import { graphql, navigate, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useCallback } from "react"
import styled from "styled-components"

import EPISODES from "../../constants/episodes"

const EpisodeListIE: React.FC = () => {
  const numEpisodes = String(EPISODES.length).padStart(2, "0")

  const data = useStaticQuery(graphql`
    fragment episodeThumbnail on File {
      childImageSharp {
        fluid(maxWidth: 1105, maxHeight: 616) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      default: file(relativePath: { eq: "episodes/default.jpg" }) {
        ...episodeThumbnail
      }
      episode01: file(relativePath: { eq: "episodes/01.jpg" }) {
        ...episodeThumbnail
      }
      episode02: file(relativePath: { eq: "episodes/02.jpg" }) {
        ...episodeThumbnail
      }
      episode03: file(relativePath: { eq: "episodes/03.jpg" }) {
        ...episodeThumbnail
      }
      episode04: file(relativePath: { eq: "episodes/04.jpg" }) {
        ...episodeThumbnail
      }
      episode05: file(relativePath: { eq: "episodes/05.jpg" }) {
        ...episodeThumbnail
      }
      episode06: file(relativePath: { eq: "episodes/06.jpg" }) {
        ...episodeThumbnail
      }
      episode07: file(relativePath: { eq: "episodes/07.jpg" }) {
        ...episodeThumbnail
      }
      episode08: file(relativePath: { eq: "episodes/08.jpg" }) {
        ...episodeThumbnail
      }
      episode09: file(relativePath: { eq: "episodes/09.jpg" }) {
        ...episodeThumbnail
      }
    }
  `)

  const handleEpisodeSelect = useCallback(
    (time) => (): void => {
      navigate("/", { state: { time } })
    },
    []
  )
  return (
    <Container>
      {EPISODES.map((episode, index) => {
        const episodeNumber = `${index + 1}`.padStart(2, "0")
        return (
          <EpisodeBlock key={`episode-${episodeNumber}`}>
            <EpisodeThumbnailContainer
              onClick={handleEpisodeSelect(episode.time)}
            >
              <EpisodeThumbnail
                fluid={
                  data[`episode${episodeNumber}`]
                    ? data[`episode${episodeNumber}`].childImageSharp.fluid
                    : data["default"].childImageSharp.fluid
                }
                alt={`Episode ${episodeNumber} thumbnail`}
                className="episode-thumbnail"
              />
              <PlayButton
                src={require("../../images/icons/play.svg")}
                alt={`Episode ${episodeNumber} play button`}
                className="play-button"
              />
            </EpisodeThumbnailContainer>
            <EpisodeDetails>
              <div className="flex">
                <EpisodeTitle>{episode.title}</EpisodeTitle>
                <div className="flex number">
                  <EpisodeNumber>{episodeNumber}</EpisodeNumber>
                  <TotalEpisodes>/ {numEpisodes}</TotalEpisodes>
                </div>
              </div>
              <EpisodeDescription>{episode.description}</EpisodeDescription>
            </EpisodeDetails>
          </EpisodeBlock>
        )
      })}
    </Container>
  )
}

export default EpisodeListIE

const Container = styled.div`
  overflow-y: scroll;
  padding-top: 140px !important;
  background-color: white;
  height: 100vh;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media (${(props): any => props.theme.mediaQueries.md}) {
    padding-top: 180px !important;
  }

  @media (${(props): any => props.theme.mediaQueries.lg}) {
    padding: 40px 40px 0 40px;
  }
`

const EpisodeBlock = styled.div`
  width: 100%;
  padding: 0 50px;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    padding: 0 30px;
    width: 33.3%;
  }
`

const EpisodeThumbnailContainer = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  @media (${(props): any => props.theme.mediaQueries.md}) {
    .episode-thumbnail {
      picture {
        img {
          transform: scale(1.1);
          transition: transform 3s ease !important;
        }
      }
    }
    .play-button {
      transform: scale(0.8);
      transition: transform 0.5s ease;
    }
    &:hover {
      .episode-thumbnail {
        picture {
          img {
            transform: scale(1);
          }
        }
      }
      .play-button {
        transform: scale(1);
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
`

const EpisodeThumbnail = styled(Img)`
  height: 100%;
  @media (${(props): any => props.theme.mediaQueries.md}) {
  }
`

const PlayButton = styled.img`
  position: absolute;
  right: -35px;
  bottom: 35px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #f2d65a;
  -webkit-box-shadow: -2.11px -4.5px 13px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -2.11px -4.5px 13px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -2.11px -4.5px 13px 0px rgba(0, 0, 0, 0.15);
  @media (${(props): any => props.theme.mediaQueries.md}) {
    width: 78px;
    height: 78px;
  }
`

const EpisodeDetails = styled.div`
  margin-top: 30px;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.number {
      justify-content: end;
      min-width: 100px;
      margin-top: 30px;
      @media (min-width: 1024px) {
        min-width: 125px;
        margin-top: unset;
      }
    }
  }
`

const EpisodeTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  align-self: center;
  margin-top: 30px;
  color: #000;
  @media (${(props): any => props.theme.mediaQueries.md}) {
    margin-top: 0px;
    padding-right: 5px;
  }
`

const EpisodeNumber = styled.p`
  color: #d7d7d7;
  letter-spacing: -0.07em;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  text-align: right;
  font-family: "RMB-Sans-Regular";
  @media (${(props): any => props.theme.mediaQueries.md}) {
    text-align: left;
    font-size: 4.2vw;
    line-height: 4.2vw;
    height: 4vw;
  }
`

const TotalEpisodes = styled.p`
  align-self: flex-end;
  font-weight: bold;
  color: #010000;
  letter-spacing: 0.1em;
  margin-bottom: 8px;
  margin-left: 5px;
`

const EpisodeDescription = styled.p`
  color: #919191;
  font-weight: 300;
  font-size: 9pt;
  font-style: italic;
  font-family: "RMB-Sans";
  line-height: 12pt;
  letter-spacing: 0.1em;
  padding-right: 1vw;
  margin-top: 14px;
  @media (${(props): any => props.theme.mediaQueries.md}) {
    font-size: 12pt;
    line-height: 25px;
    max-width: 300px;
  }
`
