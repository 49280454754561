import React from "react"
import SEO from "../components/SEO"
import EpisodeListIE from "../components/Episodes/EpisideListIE"
import Header from "../components/Layout/Header"

const Episodes: React.FC = () => {
  return (
    <>
      <SEO title="Episodes" />
      <Header />
      <EpisodeListIE />
    </>
  )
}

export default Episodes
